<template>
  <div>
    <maxEventApplicants />
    <webinarOption class="webinar" />
    <saveBtn v-if="checkData()" class="saveBtn" :callback="setSave" />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    maxEventApplicants() {
      return import("@/components/business/applicants/maxEventApplicants");
    },
    webinarOption() {
      return import("@/components/business/webinar");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "settings" });
    }
  }
};
</script>
<style lang="sass" scoped></style>
